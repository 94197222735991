.menu {
    display: flex;
    flex-direction: column;
    padding-top: 10%;
    width: 230px;
    .menu-list {
        color: #202020;
        padding: 0;
        width: 100%;
        // .MuiListItem-root:hover {
        //     background: #fff;
        // }
        :hover,
        .Mui-selected {
            color: #eb5757;
        }
        .menu-item-icon {
            padding: 0 2%;
            justify-content: center;     
        }
    }
}