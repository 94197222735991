@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  padding: 0;
  height: 100%;
  box-sizing: border-box;
  width: 100%;
  font-family: 'Poppins', sans-serif;
  /* src: url('./static/assets/css/Poppins/Poppins-Regular.ttf'); */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  /* scrollbar-color: #eb5757 #f5f5f5a6;
  scrollbar-width: thin !important; */
}
html, #root {
  height: 100%;
}

h2 {
  font-family: 'Poppins';
}
.cta {
  color: #FFF;
  background-color: #EB5757;    
  border: 1px solid #a6b1af;
}
.cta:hover {
  background-color: #e34d4dde;
}   
.btn-back {
  color: #222221 !important;
}


.hidescrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hidescrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

@layer base {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}