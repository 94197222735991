@tailwind base;
@tailwind components;
@tailwind utilities;
#root {
    .light-faded-frame {
        background-color: rgba(255, 255, 255, 0.2);
        position: relative;
        padding: 1% 2% 2%;
        border-radius: 3%;
    }
    .btn {
        color: #666666;
        .faded-borders {
            border: 1px solid #a6b1af;
        }
    }
    .color-primary {
        color: #EB5757;
    }
    .flex-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .flex-content-without-space {
        display: flex;
        align-items: center;
    }

}

body{
    scroll-behavior: smooth;
}