.modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgba(0, 0, 0, 0.5); /* Black w/ opacity */
    backdrop-filter: blur(5px); /* Blur background */
    justify-content: center;
    align-items: center;
  }
  
  .modal.show {
    display: flex; /* Show the modal */
  }
  
  .modal-content {
    background-color: white;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%; /* Could be more or less, depending on screen size */
    max-width: 500px;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    position: relative;
  }
  
  .close-button {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 20px;
  }
  
  .close-button:hover,
  .close-button:focus {
    color: black;
    text-decoration: none;
  }
  
  /* Additional styles for form elements within the modal */
  .modal-content form {
    display: flex;
    flex-direction: column;
  }
  
  .modal-content .form-group {
    margin-bottom: 15px;
  }
  
  .modal-content .form-group label {
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .modal-content .form-group input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
  }
  
  .modal-content .form-group input:focus {
    border-color: #EB5757;
    outline: none;
  }
  
  .modal-content .form-group .error-message {
    color: red;
    font-size: 12px; /* Slightly smaller text */
  }
  
  .modal-content form button {
    background-color: #EB5757;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .modal-content form button:hover {
    background-color: #EB5757;
  }
  
  /* Additional styles for modal headers and text */
  .modal-content h2 {
    margin-top: 0;
    font-size: 22px; /* Slightly smaller header */
  }
  
  .modal-content p {
    margin: 10px 0;
    font-size: 14px;
    color: #555;
  }
  