/* UserProfile.scss */

.profile {
  padding: 20px;
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 500px;

  &-header {
    text-align: center;
    margin-bottom: 20px;

    img {
      width: 120px;
      height: 120px;
      border-radius: 50%;
      margin-bottom: 10px;
    }

    h2 {
      font-size: 24px;
      font-weight: bold;
    }

    .credits {
      color: #eb5757;
      font-size: 20px;
      font-weight: bold;
    }

    .role {
      color: #888;
      font-size: 16px;
    }
  }

  .profile-section {
    margin-bottom: 20px;

    h3 {
      margin-bottom: 10px;
      font-size: 24px;
      font-weight: bold;
    }

    .profile-item {
      background: #f9f9f9;
      padding: 10px;
      border-radius: 8px;
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .designation_time{
        width: 80%;
        font-size: 15px;
      }

      button {
        background-color: #eb5757;
        color: #fff;
        border: none;
        padding: 5px 10px;
        border-radius: 4px;
        position: relative;
        right:35px;
        cursor: pointer;

        &:hover {
          background-color: #d64e4e;
        }
      }
    }

    .edu_degree {
      margin-left: 2px;
      margin-bottom: 2px;
      max-width: 200px;
      word-wrap: break-word;
      font-size: 13px;
    }

    .delete-button {
      background-color: #eb5757;
      color: #fff;
      border: none;
      padding: 5px 10px;
      border-radius: 4px;
      cursor: pointer;

      &:hover {
        background-color: #d64e4e;
      }
    }

    .add-button {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 10px;

      .MuiIconButton-root {
        color: #eb5757;
        font-size: 32px;
      }
    }
  }
}
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.6); /* Semi-transparent background */
  z-index: 1000;
}

.popup-inner {
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  max-width: 400px;
  width: 100%;
}

.popup-inner h2 {
  margin-bottom: 20px;
  font-size: 17px;
  font-family: "poppins";
}

.popup-inner button {
  background: #f10000c3;
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 0 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease;

  &:hover {
    background: #f10000c3;
  }

  &:focus {
    outline: none;
  }
}

.popup-inner button:first-of-type {
  background: #008df1c3;
  transition: background 0.3s ease;

  &:hover {
    background: #008df1c3;
  }
}