.pop-page {
  


  .error-message{
    color:red;
    margin-left: 12%;
    margin-top:1%;
    margin-bottom: 1%;
  }
  .pop-details {
    display: flex;

    .pop_image {
      width: 300px;
      padding: 20px;
      .pop_img {
        width: 270px;
        object-fit: fill;
      }
    }
    .pop_about {
      width: 350px;
      padding: 20px;

      .pop_about_text {
        width: 350px;
        font-family: Poppins;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.01em;
        text-align: left;
        color: #000000;
        margin-top: 0px;
        margin-bottom: 10px;
      }

      .pop-card__stars {
        display: flex;
        margin-top: 4px;

        .pop-card__star {
          font-size: 30px; // Adjust size as needed
          color: #d0d0d0; // Default color for empty stars

          &.filled {
            color: #ffcc00; // Golden color for filled stars
          }
        }

        &:hover {
          transform: scale(1.02);
        }

        &__header {
          display: flex;
          width: 100%;
        }

        &__image {
          width: 100px;
          height: 100px;
          border-radius: 50%;
          margin-right: 16px;
        }

        &__info {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }

        &__name {
          margin: 0;
          font-size: 18px;
          font-weight: bold;
        }

        &__title,
        &__experience,
        &__minutes,
        &__candidates {
          margin: 4px 0;
          font-size: 14px;
          color: #555;
        }

        &__additional {
          width: 100%;
          margin-top: 16px;
        }

        &__footer {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          margin-top: 8px;
        }

        &__rate {
          font-weight: bold;
          font-size: 16px;
        }

        &__connect {
          background-color: #eb5757;
          color: #fff;
          border: none;
          padding: 8px 16px;
          border-radius: 4px;
          cursor: pointer;
          transition: background-color 0.2s;

          &:hover {
            background-color: #d64e4e;
          }
        }
      }

      .pop_about_text_paragraph {
        width: 350px;
        font-family: Poppins;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.01em;
        text-align: left;
        color: #747474;
        margin-top: 0px;
        margin-bottom: 10px;
      }
    }
    .social-media {
      gap: 20px;
    }
  }
  .social-media {
    gap: 20px;
  }
  .divider {
    //   color: #ebebeb;
    border: 1px solid;
    margin-right: 20px;
  }
  .pop_description {
    margin-top: 20px;
    // margin-left: 5%;

    .analytics {
      h1 {
        font-family: Poppins;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        text-align: left;
        width: 837px;
      }
      .analytics_container {
        width: 900px;
        height: 80px;
        top: 452px;
        left: 295px;
        gap: 13px;
        opacity: 0px;
        //   border: 2px solid rebeccapurple;
        display: flex;

        .anaytics_data {
          width: 270.67px;
          height: 68px;
          border-radius: 12px;
          padding: 12px;
          gap: 17px;
          color: #ffffff;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

          h4 {
            font-family: Poppins;
            font-size: 12px;
            font-weight: 600;
            line-height: 18px;
            text-align: left;
            color: #0e0f1d;
          }
          h5 {
            font-family: Poppins;
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
            text-align: left;
            color: #5f5f5f;
          }
        }
      }
    }
  }
  .divider2 {
    //   color: #ebebeb;
    border: 1px solid #ebebeb;
    width: 1200px;
    gap: 0px;
    border: 1px 0px 0px 0px;
    opacity: 0px;
  }

  //   =========================Analytics==========================================

  &::after {
    content: "";
    // position: absolute;
    // top: 48%;
    // left: 0;
    // width: 100%;
    // border-top: 1px solid #ebebeb; // Adjust color and width as needed
  }

  //   =================================================education

  .experience {
    height: 183px;
    display: flex;
    margin-top: 100px;

    .education {
      width: 70%;
      display: flex;
      h3 {
        font-family: Poppins;
        font-size: 20px;
        font-weight: 500;
        line-height: 30px;
        text-align: left;
        position: relative;
        top: -23px;
        left: 6%;
      }
      .addIcon {
        position: relative;
        color: #808080;
        left: 70%;
      }
      .education_details {
        position: relative;
        right: 12%;
      }
      .education_fields {
        height: 36px;
        margin-top: 30px;
        position: relative;
        left: 19%;

        h4 {
          font-family: Poppins;
          font-size: 14px;
          font-weight: 500;
          line-height: 21px;
          text-align: left;
          margin-bottom: -27px;
        
        }
        h5 {
          font-family: Poppins;
          font-size: 14px;
          font-weight: 300;
          line-height: 21px;
          text-align: left;
          position: relative;
          left:0%;
        }
        button {
          position: relative;
          left: 440px;
          bottom: 68px;
          margin-top: 15px;
          background-color: #eb5757;
          border-radius: 8px;
          padding: 4px, 8px, 4px, 8px;
          color: white;
          border-color: #eb5757;
        }
      }
    }

    // ==============================================work experience
    .work_experience {
      width: 70%;
      display: flex;

      h3 {
        font-family: Poppins;
        font-size: 20px;
        font-weight: 500;
        line-height: 30px;
        text-align: left;
        position: relative;
        top: -23px;
        left: 36px;
      }
      .addIcon {
        position: relative;
        color: #808080;
        left: 70%;
      }
      .work_experience_details {
        position: relative;
        right: 16%;
      }
      .work_experience_fields {
        height: 36px;
        margin-top: 30px;

        h4 {
          font-family: Poppins;
          font-size: 14px;
          font-weight: 500;
          line-height: 21px;
          text-align: left;
          margin-bottom: -16px;
          position: relative;
          left:-3%;
        }
        h5 {
          font-family: Poppins;
          font-size: 14px;
          font-weight: 300;
          line-height: 21px;
          text-align: left;
          position: relative;
          left:-2%;
        }
        button {
          position: relative;
          left: 490px;
          bottom: 63px;
          margin-top: 15px;
          background-color: #eb5757;
          border-radius: 8px;
          padding: 4px, 8px, 4px, 8px;
          color: white;
          border-color: #eb5757;
          bottom:90px
        }
      }
    }
  }

  //time slots

  .time_slots {
    margin-top: 10px;
    position: relative;
    left: 3px;

    .heading {
      width: 70%;
      display: flex;

      h3 {
        font-family: Poppins;
        font-size: 20px;
        font-weight: 500;
        line-height: 30px;
        text-align: left;
        position: relative;
        top: 0px;
        left: 17px;
      }
      .addIcon {
        position: relative;
        color: #808080;
        position: relative;
        top: 24px;
        left: 30%;
      }
    }
  }
  // calender

  .calendar {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    .calendar_selection {
      display: flex;
      gap: 20px;
    }
    .selected_dates {
      display: flex;
      gap: 10px;

      .fromDate,
      .toDate {
        width: 24%;
        button {
          font-family: Poppins;
          font-size: 14px;
          font-weight: 400;
          line-height: 21px;
          text-align: left;
          color: gray;
          border: 1px solid #3da817;
          padding: 5px;
          // border-color:   #3DA817;
          border-radius: 9px;
        }
      }
    }
  }

  .container_question{
    display: flex;
    gap:100px;
    p{
      margin-left: 2%;
      font-size: 17px;
      font-weight: 400px;
    }
    .input_question{
      width: 75%;
      font-family: poppins;
      border-radius: 15px;
      font-size: 18px;
      color:#101010d4;
      padding: 10px;
      word-wrap: break-word;
      box-sizing: border-box;
      height: 100px;
      margin: 8px 0;
      border: 1px solid #ccc;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.096);
      resize: vertical; /* Allow vertical resize but disable horizontal resize */
      overflow-wrap: break-word;
      white-space: pre-wrap;
      

      input[type="text"]:focus {
        border-color: #0066cc;
        box-shadow: 0 0 8px rgba(0, 102, 204, 0.6);
        outline: none;
        box-sizing: border-box;
        resize: vertical; /* Allow vertical resize but disable horizontal resize */
    }
    }
  
  }

  .time_slider {
    display: flex;
    gap: 10%;
    margin-top: 5%;
    h3 {
      font-family: Poppins;
      font-size: 20px;
      font-weight: 400;
      line-height: 30px;
      letter-spacing: -0.01em;
      text-align: left;
      margin-left: 3%;
    }
  }

  .booking {
    margin-left: 3%;

     h3 {
      font-family: Poppins;
      font-size: 19px;
      font-weight: 400;
      line-height: 22px;
      width:13%;
    }
    .book_slot {
      display: flex;
    }
    .timings {
      display: flex;
    }
    .total_cost {
      display: flex;
    }
  }


  .Book{
    button{
      background-color:#EB5757;
      border-radius: 3px;
      color:white;
      padding: 5px;
      font-size: 15px;
      font-family: Poppins;
      width: 130px;
      position: relative;
      left: 90%;
      cursor: pointer;
    }
  }
  &::after {
    content: "";
    position: absolute;
    top: 48%;
    left: 0;
    width: 100%;
  }
}
