.terms-page {
    .terms-title {
        display: flex;
        .btn-back {
            margin: 0 3% 0 1%;
        }
    }
    .terms-content-container {
        display: flex;
        flex-direction: column;
        padding-left: 7%;
        .terms-content {
            color: #6b6b6b;
            font-size: 0.8rem;
            width: 90%;
            height: 55vh;
            text-align: justify;
            p {
                overflow: scroll;
                height: inherit;
                padding: 0 12px;
                overflow-x: hidden;
                scrollbar-color: #eb5757 #f5f5f5;
                scrollbar-width: thin;
            }
            .terms-actions {
                padding: 0 5%;
                width: 30%;
                float: right;
                justify-content: space-around;
                display: flex;
                button {
                    padding: 10px 35px;
                    border-radius: 8px;
                    cursor: pointer;
                }
                .secondary {
                    border: none;
                    background: none;
                    color: #eb5757;
                }
                .secondary:hover {
                    color: #e34d4daf;
                }
            }
        }
    }
}
